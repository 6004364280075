import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

type IProps = {
  part: {
    title: string;
    books: {
      title: string;
      slug: string;
    }[];
  };
};

export const BookPart: React.FC<IProps> = ({ part: { books, title } }) => {
  return (
    <>
      <PartTitle>{title}</PartTitle>
      <PartStyle>
        {books.map((book) => {
          return (
            <li key={book.title}>
              <Link to={book.slug}>{book.title}</Link>
            </li>
          );
        })}
      </PartStyle>
    </>
  );
};

const PartTitle = styled.h2`
  margin: 10px 0 10px 0;
`;

const PartStyle = styled.ul`
  margin-bottom: 0;
  li {
    margin-left: 32px;
    margin-bottom: 0;
  }
`;
