import React from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import { BookPart } from '../components/common/BookPart';
import { Card, CardContent } from '../components/common/Card';
import { BookCation } from '../components/SideMenu/BookCaption';

type IProps = {
  data: any;
};

export const BookPageTemplate: React.FC<IProps> = (props) => {
  const { data } = props;
  const {
    site: {
      siteMetadata: { title },
    },
  } = data;

  return (
    <Layout>
      <div>
        <Helmet>
          <title>{`${data.allJsonJson.edges[0].node.title} - ${title}`}</title>
          {/*<meta name="description" content={description} />*/}
        </Helmet>
        {/*<BookCation title={data.allJsonJson.edges[0].node.title} />*/}
        <Card>
          <CardContent>
            {data.allJsonJson.edges[0].node.parts.map((part: any) => {
              return <BookPart key={part.title} part={part} />;
            })}
          </CardContent>
        </Card>
      </div>
    </Layout>
  );
};

export default BookPageTemplate;

export const pageQuery = graphql`
  query BookPageBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        subtitle
      }
    }
    allJsonJson(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          title
          slug
          parts {
            title
            books {
              slug
              title
            }
          }
        }
      }
    }
  }
`;
